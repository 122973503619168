import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../component/layout/Layout";
import useRequest, { client, downloadBlobFile } from "../../utils/useRequest";
import EditClaimComponent from "./EditClaimComponent";
import toastr from "toastr";
import { useSelector } from "react-redux";
import AddNoteComponent from "../../component/AddNoteComponent";
import AttachmentComponent from "../../component/AttachmentComponent";
import { Badge, Button } from "reactstrap";
import _ from "lodash";
import ClaimPayment from "./ClaimPayment";
import { ChangeLog, Invoice } from "./Claims";

const EditClaim = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { role } = useSelector((state: any) => state.roles);
  const submitRef = useRef<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useSelector((state: any) => state.user);
  const [exit, setExit] = useState(false);
  const [mode, setMode] = useState({ mode: false, user: "", user_id: null });
  const [data, setData] = useState({} as any);
  const [redata, setRedata] = useState({} as any)

  const fetchApi = async() => {
    setIsLoading(true);
    const response = await client.get(`/api/claims/${params.id}/edit`);
    if(response.data.status){
      setData(response.data)
      setRedata(response.data.data)
      setMode({
        mode: !!response?.data?.data.read_mode,
        user: response?.data?.data.read_mode_user?.name ?? "",
        user_id: response?.data?.data.read_mode_by,
      });
    }
    setIsLoading(false)
  }

  useEffect(() => {
    fetchApi();
  }, []);

  const handleSubmit = async (values: any) => {
    setIsLoading(true);
    try {
      const response = await client.put(`/api/claims/${params.id}`, values);
      if (response?.data?.status) {
        setRedata(response.data.data)
        setMode({
          mode: !!response?.data?.read_mode,
          user: response?.data?.read_mode_user?.name ?? "",
          user_id: response?.data?.read_mode_by,
        });
        toastr.success(response?.data.message);
        if (exit) {
          navigate(`/${role}/claims`);
        }
      } else {
        toastr.error(response?.data.message);
      }
    }
    catch(e:any) {
      
    }
    setIsLoading(false);
  };

  const formatDate = (date: any) => {
    if (date == "-" || !date) return "";
    let dates = date.split("/");
    let year = dates[2];
    let month = dates[0];
    let day = dates[1];

    // console.log(year + "-" + month + "-" + day);
    // return moment(date, "MM/DD/YYYY").format("YYYY-MM-DD");
    return year + "-" + month + "-" + day;
  };

  const breadCrum = [
    { title: "Claims", to: "/claims" },
    { title: "Edit Claim" },
  ];

  const exportCsv = async (id: any) => {
    const url = `/api/claim/export/${id}`;
    const fileName = `Claim B-${id}.csv`;
    await downloadBlobFile(url, fileName);
  };

  const downloadPdf = async (id: any) => {
    const url = `/api/claim/export-pdf/${id}`;
    const fileName = `Claim B-${id}.pdf`;
    await downloadBlobFile(url, fileName);
  };

  const formChangeDetected = async (val: any, readOnly = true) => {
    if (mode.mode != readOnly) {
      const response = await client.put(`/api/claims/read-only/${params.id}`, {
        readOnly,
      });
      if (response.data.status) {
        setMode({
          mode: readOnly,
          user: response.data?.data?.user,
          user_id: response.data?.data?.user_id,
        });
      }
    }
  };

  const ExtraButton = () => {
    return (
      <div className="table-action dropend mx-2 mt-1">
        <button
          type="button"
          className="btn btn-sm btn-primary"
          data-bs-toggle="dropdown"
        >
          <i className="fa fa-bars" aria-hidden="true"></i>
        </button>
        <ul className="dropdown-menu" aria-labelledby="" style={{}}>
          <li>
            <a className="dropdown-item" onClick={() => exportCsv(params.id)}>
              Export CSV
            </a>
          </li>
          <li>
            <a className="dropdown-item" onClick={() => downloadPdf(params.id)}>
              Download Pdf
            </a>
          </li>
          <li>
            <Invoice
              id={[params.id]}
              isPatient={true}
              title="Patient Invoice"
              type={"patient_invoice"}
              fileName={`Patient Invoice B-${params.id}`}
            />
          </li>
          <li>
            <Invoice
              id={[params.id]}
              isPatient={false}
              title="Company Invoice"
              type={"company_invoice"}
              fileName={`Company Invoice B-${params.id}`}
            />
          </li>
          <li>
            <Invoice
              id={[params.id]}
              isPatient={false}
              title="Manufacturer Invoice"
              type={"manufacturer_invoice"}
              fileName={`Manufacturer Invoice B-${params.id}`}
            />
          </li>
          <li>
            <ChangeLog modelName="Claim" subjectId={params.id} />
          </li>
        </ul>
      </div>
    );
  };

  return (
    <Layout
      breadCrum={breadCrum}
      isLoading={!data?.data}
      AddButton={
        <Button
          className={"btn-small"}
          color="primary"
          onClick={() => navigate(`/${role}/claims/create`)}
        >
          Add Claim
        </Button>
      }
      extra={<ExtraButton />}
    >
      <div className="claim-row">
      {!!mode.mode && (
        <div className="my-2">
          <Badge color="warning">Read Only By: {mode.user}</Badge>
          {mode.user_id === user.id && (
            <Button
              color="danger mx-2"
              size="sm"
              onClick={() => formChangeDetected({}, false)}
            >
              Clear read mode
            </Button>
          )}
        </div>
      )}
      <div className="card py-2">
        {/*<h3 className="mb-3">Update Claim</h3>*/}
        <EditClaimComponent
          reference={submitRef}
          onSubmit={handleSubmit}
          initialValue={{
            ...data?.data,
            patient_birthday: formatDate(data?.data?.patient_birthday),
            date_received: formatDate(data?.data?.date_received),
            date_submitted: formatDate(data?.data?.date_submitted),
            checkoff_date: formatDate(data?.data?.checkoff_date),
            date_of_service: formatDate(data?.data?.date_of_service),
            date_of_injury: formatDate(data?.data?.date_of_injury),
            payment_receipt_date: formatDate(data?.data?.payment_receipt_date),
            elite_paid_date: formatDate(data?.data?.elite_paid_date),
            rework_date: formatDate(data?.data?.rework_date),
            second_payment_receipt_date: formatDate(
              data?.data?.second_payment_receipt_date
            ),
            products: data?.data?.claim_products,
            missing_info: _.lowerCase(data?.data?.missing_info),
          }}
          formChangeDetected={formChangeDetected}
          reData={redata}
        />
        <br />
      </div>
      <ClaimPayment modelId={params.id} />
      <AttachmentComponent modelType="claim" modelId={params.id} />
      <AddNoteComponent modelType="claim" modelId={params.id} />
      <div className="card">
        <div className="card-body footer-bottom">
          <Button
            disabled={isLoading}
            color="primary"
            onClick={() => submitRef?.current?.submitRef()}
          >
            Update Claim
            {isLoading && (
              <div
                className="spinner-grow spinner-grow-sm mx-2"
                role="status"
              ></div>
            )}
          </Button>
          <Button
            className="mx-2"
            disabled={isLoading}
            color="danger"
            onClick={() => {
              setExit(true);
              submitRef?.current?.submitRef();
            }}
          >
            Update And Exit
            {isLoading && (
              <div
                className="spinner-grow spinner-grow-sm mx-2"
                role="status"
              ></div>
            )}
          </Button>
        </div>
      </div>
      </div>
    </Layout>
  );
};

export default EditClaim;
